import {Injectable} from '@angular/core';
import {AuthService} from './auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private isInitialized = false;

  constructor(private authService: AuthService) {
  }

  loadChat(): void {
    if (this.isInitialized) {
      return;
    }

    if (!this.authService.isLoggedIn()) {
      return;
    }
    this.isInitialized = true;

    console.error(
      'Number',
      this.authService.currentUserValue?.agency?.document_number,
    );
    this.addChatScript();
  }

  private addChatScript(): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src =
      'https://gattinoni--partial.sandbox.my.site.com/ESWChatAvanzataB2BADV1726568359557/assets/js/bootstrap.min.js';
    script.onload = () => this.initEmbeddedMessaging();
    document.body.appendChild(script);
  }

  private initEmbeddedMessaging(): void {
    const authUser = this.authService.currentUserValue;
    try {
      const win = window as any;
      win.embeddedservice_bootstrap.settings.language = 'it';

      win.embeddedservice_bootstrap.init(
        '00DbX0000002ayf',
        'Chat_Avanzata_B2B_ADV_Italia',
        'https://gattinoni--partial.sandbox.my.site.com/ESWChatAvanzataB2BADV1726568359557',
        {
          scrt2URL: 'https://gattinoni--partial.sandbox.my.salesforce-scrt.com',
        },
      );

      const currentdate = new Date();

      window.addEventListener('onEmbeddedMessagingReady', () => {
        win.embeddedservice_bootstrap.prechatAPI.setVisiblePrechatFields({
          _firstName: {value: authUser?.first_name},
          _lastName: {value: authUser?.last_name},
          _email: {value: authUser?.email},
          Assistenza_Su: {value: ''},
          N_Pratica: {
            value:
              authUser?.agency?.document_number !== undefined
                ? authUser?.agency?.document_number
                : '',
          },
          Data_Partenza: {value: currentdate},
        });
        win.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
          Codice_Cliente: authUser?.agency?.vat_number !== undefined
            ? authUser?.agency?.vat_number
            : '',
        });
      });
    } catch (err) {
      console.error('Error initializing Embedded Messaging: ', err);
    }
  }
}
