import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { ChildActivationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from './shared/services/auth/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet>
      <app-loader></app-loader>
    </router-outlet>
  `
})
export class AppComponent implements OnInit, OnDestroy {

  title = environment.title;
  subscriptions = new Subscription();

  constructor(public router: Router, private authService: AuthService, private titleService: Title) {
    // Set the name of page based on navigation place where we are
    this.router.events.pipe(filter(event => event instanceof ChildActivationEnd)).subscribe(event => {
      let snapshot = (event as ChildActivationEnd).snapshot;

      while (snapshot.firstChild !== null) {
        snapshot = snapshot.firstChild;
      }

      this.titleService.setTitle(snapshot.data.title || environment.title);
    });
  }


  ngOnInit(): void {
    this.subscriptions.add(
      this.authService.currentUser.subscribe(token => {
        // const win = window as any;
        // if (!win.embedded_svc) {
        //   const s = document.createElement('script');
        //   s.setAttribute('src', 'https://gattinoni.my.salesforce.com/embeddedservice/5.0/esw.min.js');
        //   s.onload = () => {
        //     win.initESW(null);
        //   };
        //   document.body.appendChild(s);
        // } else {
        //   win.initESW('https://service.force.com');
        // }

        (window as any).initESW('https://service.force.com');
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
